<template>
  <v-card class="mb-6 pa-2">

      <dot-menu
        :isLoading="loading"
        @exportCsv="exportCSV"
        :csvButton="{
          csvData,
          filename: `export-rmra-${this.dates[0]}-${this.dates[1]}.csv`,
        }"
      />
    <v-data-table
      locale="fr-FR"
      :headers="headers"
      :items="items"
      :sort-by="'updated'"
      :sort-desc="true"
      :loading="loading"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
      }"
    >
      <template v-slot:item.time="{ item }">
        {{ getTimeStr(new Date(item.date)) }}
      </template>

      <template v-slot:item.date="{ item }">
        {{ getDateStr(new Date(item.date)) }}
      </template>
    </v-data-table>
  </v-card>
</template>
<style scoped></style>
<script>
import axios from "@axios";
import { mdiPencil, mdiTrashCan } from "@mdi/js";
import debounce from "lodash/debounce";
import { getDateStr, getTimeStr } from "@/utils/dates";
import DotMenu from "@/components/common/menus/DotMenu";

export default {
  name: "EventsTable",
  components: {
    DotMenu,
  },
  data() {
    return {
      search: "",
      csvData: "",
      dialogModel: {
        dialog: false,
        id: -1,
        entryName: "",
      },
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Type",
          align: "start",
          value: "type",
        },
        {
          text: "Cagtégorie",
          value: "category",
        },
        {
          text: "Date",
          value: "date",
        },
        {
          text: "Time",
          value: "time",
        },
      ],
      icons: {
        mdiPencil,
        mdiTrashCan,
      },
    };
  },
  methods: {
    getTimeStr,
    getDateStr,
    exportCSV() {
      this.csvData = "ID,Type,Category,Date,Time\n";
      this.items.forEach((item) => {
        this.csvData += `${item.id},${item.type},${item.category},${getDateStr(new Date(item.date))},${getTimeStr(new Date(item.date))}\n`;
      });
    },
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters["common/getLoading"];
      },
      set(val) {
        this.$store.dispatch("common/updateLoading", val);
      },
    },
    events() {
      return this.$store.getters["rmra/getEvents"];
    },
    filteredEvents() {
      return this.$store.getters["rmra/getFilteredEvents"];
    },
    items() {
      if (this.filteredEvents.length > 0 && this.filteredEvents.length < this.events.length) {
        return this.filteredEvents;
      } else {
        return this.events;
      }
    },
    dates() {
      return this.$store.getters["rmra/getDates"];
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-with-ga-id {
  max-width: 300px;
}
.above-table {
  width: 100%;
}
.search-by-site-id {
  width: 100px;
}
</style>
